/* ProductoInsumo */
/* HeroCategory component */
.hero-category-comercial {
  height: 50vh;
  background-image: url("../../assets/images/productos/dibal_header.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-category-industria {
  height: 50vh;
  background-image: url("../../assets/images/productos/IndicadorPlataforma.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-category-insumos {
  height: 50vh;
  background-image: url("../../assets/images/productos/GeneralEtiquetas.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.insumo-hero-title {
  background-color: #a00202;
  color: #ffffff;
  font-size: 20px;
  width: auto;
  text-align: center;
  position: absolute;
  left: 3%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  padding: 10px 15px;
}

/* InsumoCategory components */
.category-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 10%;
}
.category-container h2 {
  color: #a00202;
  margin-top: 40px;
}
.category-container h3 {
  font-size: 1.6em;
  text-align: left;
  transition: font-size 0.5s;
  -webkit-transition: font-size 0.5s;
  -moz-transition: font-size 0.5s;
  -o-transition: font-size 0.5s;
  transition: font-size 0.5s;
}
.category-container h3:hover{
  font-size: 1.7em;
}
.category-container h4{
  font-size: 1.5em;
  align-self: center;
  color: #a00202;
}
.category-container p {
  margin-top: -10px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
}
.category-container p,center{
  align-self: center;
}
.category-container table, th, td{
  border: 2px solid #a00202;
  border-collapse: collapse;
  padding: 5px 10px;
  align-self: center;
  margin: 10px 0 30px 0;
}
.category-interact-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.category-subsection-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.category-subsection-container h3{
  margin: 7px 0px;
}
.category-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
  padding: 40px 0;
}
.category-list a {
  margin: 10px 20px;
  text-decoration: none;
}
.category-list p{
  margin: 5px 4vw;
}
.category-item {
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
  background-color: #a00202;
  color: #ffffff;
  margin: 5%;
  width: 140px;
  height: 140px;
  padding: 20px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.category-item:hover {
  border: 10px solid #6d0606;
  transition: border 0.5s ease-in-out;
}
.category-item:active {
  box-shadow: none;
  transition: box-shadow 0.5 ease-in-out;
  text-decoration: none;
}
.category-item:focus {
  border: 10px solid #6d0606;
  outline: none;
}

/* InsumoItem component*/
.item-container {
  /*align-self: center;*/
  justify-content: center;
  border-left: 4px solid #a00202;
  transition: border-top 0.5 ease-in-out;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 60px;
}
.item-container img {
  width: 400px;
  border-radius: 20px;
}
.item-container .btn-category {
  margin: 0;
  padding: 0;
  padding-top: 40px;
  align-self: center;
}
.item-container .btn-category .button-more-info-xs {
  margin: 0;
}

/* Media Query */

@media screen and (max-width: 1400px) {
  .hero-category-comercial {
    height: 45vh;
  }
  .hero-category-industria {
    height: 45vh;
  }
  .hero-category-insumos {
    height: 45vh;
  }
}

@media screen and (max-width: 1024px) {
  .hero-category-comercial {
    height: 30vh;
  }
  .hero-category-industria {
    height: 30vh;
  }
  .hero-category-insumos {
    height: 30vh;
  }
}

@media screen and (max-width: 768px) {
  .hero-category-comercial {
    height: 25vh;
  }
  .hero-category-industria {
    height: 25vh;
  }
  .hero-category-insumos {
    height: 25vh;
  }
  /* HeroCategory component */
  .insumo-hero-title {
    font-size: 0.8em;
  }
  /* InsumoCategory components */
  .category-container h2 {
    font-size: 2em;
  }
  .category-container p {
    margin-top: -10px;
    font-size: 0.9em;
  }
  .category-item {
    font-size: 0.8em;
    margin: 5% 5%;
    width: 80px;
    height: 80px;
    padding: 20px;
    border-radius: 50%;
  }
  .category-list a {
    margin: 5% 5%;
  }
  .item-container {
    align-self: center;
    width: 60vw;
    padding: 60px 30px 50px 30px;
    border-left: none;
    border-top: 4px solid #a00202;
  }
  .item-container img {
    width: 60vw;
    border-radius: 20px;
  }
  .item-container .btn-category {
    margin: 0;
    padding: 0;
    padding-top: 40px;
    align-self: center;
  }
  .item-container .btn-category .button-more-info-xs {
    width: 40vw;
    text-align: center;
    font-size: 1em;
  }
  .category-interact-container{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 320px) {
  /* HeroCategory component */
  .insumo-hero-title {
    font-size: 0.6em;
  }
  /* InsumoCategory components */
  .category-container h2 {
    font-size: 1.5em;
  }
  .category-container p {
    font-size: 0.8em;
  }
  .category-item {
    font-size: 0.6em;
    width: 70px;
    height: 70px;
  }
  .item-container .btn-category .button-more-info-xs {
    font-size: 0.8em;
  }
}
