/* Components from contacto */
.contacto-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3% 10% 5% 10%;
}
.contacto-data {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-bottom: 60px;
}

/* Asesorias component */
.contacto-asesorias-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-grow: 0; */
  width: 100%;
}
.contacto-asesorias-container h2 {
  color: #a00202;
  margin-bottom: 15px;
}
.contacto-asesorias-container p {
  font-size: 21px;
  text-align: justify;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  color: #606060;
  margin: 0;
  width: 85%;
  word-spacing: 2px;
}
.contacto-asesorias-container-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
  width: auto;
}
.contacto-asesorias-container input {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  border: none;
  background-color: #e7e7e7;
  padding: 20px;
  width: 350px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.contacto-asesorias-container textarea {
  resize: none;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  border: none;
  background-color: #e7e7e7;
  padding: 20px;
  width: 600px;
  height: 120px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.contacto-asesorias-container form .btn-category {
  margin: 0;
  margin-top: 20px;
  padding: 0;
}
.contacto-asesorias-container form .btn-category .button-more-info-s {
  margin: 0;
  width: 100px;
  text-align: center;
}

/* Contactanos component */
.contacto-contactanos-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-grow: 1; */
  width: 60%;
  padding-top: 10%;
  padding-left: 50px;

  border-left: 2px solid #a00202;
}
.contacto-contactanos-container h2 {
  color: #a00202;
  margin-bottom: 10px;
}
.buttom-h2 {
  padding-bottom: 40px;
}
.contactanos-medio {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}
.p-items {
  display: inline-block;
}
.p-items p {
  margin: 0;
  padding-left: 15px;
}
.contactanos-medio p {
  margin: 0;
  padding-left: 15px;
}
.contactanos-medio p > a {
  text-decoration: none;
  color: #606060;
}
.contactanos-medio p > a:hover {
  text-decoration:underline;
  color: #a00202;
  font-weight: bolder;
}
/* frame component */
.embed-container {
  width: 100%;
  height: 0;
  padding-top: 25%; /* (heigth*100)/width */
  position: relative;
}
.embed-iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Query */

@media screen and (max-width: 1400px) {
  
}

@media screen and (max-width: 1024px) {

  /* contancto */
  .contacto-data{
    flex-wrap: wrap
  }

  /* Asesorias contacto */
  .contacto-asesorias-container p{
    width: 80vw;
  }
  .contacto-asesorias-container h2{
    align-self: center;
  }
  .contacto-asesorias-container form{
    align-self: center;
  }
  .contacto-asesorias-container input{
    align-self: center;
    width: 70vw;
  }
  .contacto-asesorias-container textarea{
    align-self: center;
    width: 70vw;
  }
  .contacto-asesorias-container form .btn-category{
    align-self: center;
  }
  .contacto-asesorias-container form .btn-category .button-more-info-s{
    width: 60vw;
  }
  
    /* Contactanos component */
  .contacto-contactanos-container {
    align-self: center;
    border:none;
    width: 80vw;
    padding-left: 0;
  }
  .contacto-contactanos-container h2{
    align-self: center;
    text-align: center;
  } 
  .contactanos-medio{
    width: 30%;
    align-self: center;
    justify-self: flex-start;
  }
  .contacto-contactanos-container .btn-category{
    align-self: center;
    padding: 0;
  }
  .contacto-contactanos-container .btn-category .button-more-info-whatsapp{
    margin: 0;
  }

}

@media screen and (max-width: 768px) {
   /* Asesorias contacto */
   .contacto-asesorias-container p{
    font-size: 1.2em;
  }
  .contacto-asesorias-container h2{
    font-size: 1.8em;
  }
  .contacto-asesorias-container input{
    font-size: 0.8em;
  }
  .contacto-asesorias-container textarea{
    font-size: 0.8em;
  }
  .contacto-asesorias-container form .btn-category .button-more-info-s{
    font-size: 1em;
  }

   /* Contactanos component */
  .contacto-contactanos-container h2{
    font-size: 1.8em;
  } 
  .contactanos-medio{
    width: 50%;
  }
  .contacto-contactanos-container .btn-category .button-more-info-whatsapp{
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  
}

@media screen and (max-width: 320px) {
   /* Asesorias contacto */
   .contacto-asesorias-container p{
    font-size: 0.9em;
  }
  .contacto-asesorias-container h2{
    font-size: 1.4em;
  }
  .contacto-asesorias-container input{
    font-size: 0.6em;
  }
  .contacto-asesorias-container textarea{
    font-size: 0.6em;
  }
  .contacto-asesorias-container form .btn-category .button-more-info-s{
    font-size: 0.9em;
  }

   /* Contactanos component */
   .contacto-contactanos-container h2{
    font-size: 1.4em;
  } 
  .contactanos-medio{
    width: 50%;
  }
  .p-items p{
    font-size: 0.8em;
  }
  .contactanos-medio p{
    font-size: 0.8em;
  }
  .contactanos-medio p > a{
    font-size: 0.8em;
  }
}
