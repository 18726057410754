/* Components from nosotros */

/* SomosPV component */
.nosotros-somospv-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
}
.nosotros-somospv-container h1 {
  font-weight: bolder;
  color: #a00202;
}
.nosotros-somospv-container span {
  color: #383838;
}
.margin-fix {
  margin-top: -40px;
}
.nosotros-somospv-container p {
  text-align: justify;
  font-size: 28px;
  color: #606060;
  font-family: "Montserrat", sans-serif;
}
.somospv-list {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-column-gap: 80px;
  justify-items: center;
  align-items: start;
  margin-top: 70px;
}
.somos-pv-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.somos-pv-list-item h2 {
  color: #a00202;
  padding-top: 60px;
}
.somos-pv-list-item p {
  font-size: 20px;
  padding: 0 10%;
  margin-top: 0;
}
.somos-pv-list-item ol li {
  font-size: 20px;
  text-align: justify;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 5px;
  color: #606060;
}

/* SomosPVNum component */
.somospv-numeros-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  padding-top: 20px;
}
.somospv-numeros-container h1 {
  font-weight: bolder;
  color: #383838;
}
.somospv-numeros-container span {
  color: #a00202;
}
.somospv-numeros-list {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 100px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-bottom: 80px;
}
.somospv-numeros-list-items h1 {
  color: #a00202;
  font-size: 120px;
  font-weight: 700;
  margin: 0;
  text-align: center;
}
.somospv-numeros-list-items p {
  font-size: 30px;
  text-align: center;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  color: #606060;
  margin: 0;
}

/*Normas component*/
.Normas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
}
.Normas-container p {
  font-style: italic;
  font-size: 28px;
  padding-left: 20px;
}

/* Clientes component */
.nosotros-clientes-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 100px;
}
.nosotros-clientes-container h1 {
  font-weight: 700;
  text-align: center;
}
.nosotros-clientes-item {
  padding: 50px 20px;
}

/* Media Query */

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
  /* SomosPV component */
  .somospv-list {
    grid-column-gap: 40px;
    justify-content: center;
    align-content: center;
  }

  /* SomosPVNum component */
  .somospv-numeros-list {
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 10%;
  }
  .somospv-numeros-list-items h1 {
    font-size:5em;
  }
  .somospv-numeros-list-items p {
    font-size: 2em;
  }
}

@media screen and (max-width: 768px) {

  /* SomosPV component */
  .nosotros-somospv-container h1 {
    text-align: center;
    font-size: 3em;
  }
  .nosotros-somospv-container p {
    text-align: justify;
    font-size: 1.2em;
  }
  /* SomosPV component */
  .somospv-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 30px;
    justify-content: center;
    align-content: center;
  }
  .somos-pv-list-item h2 {
    font-size: 2em;
  }
  .somos-pv-list-item p{
    font-size: 1.2em;
    width: 60vw;
  }
  /* SomosPVNum component */
  .somospv-numeros-container h1 {
    font-size: 3em;
    text-align: center;
  }
  .somospv-numeros-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 20px;
  }
  .somospv-numeros-list-items h1 {
    font-size:3.5em;
  }
  .somospv-numeros-list-items p {
    font-size: 1.8em;
  }
  .Normas-container {
    flex-wrap: wrap;
  }
  .Normas-container p {
    text-align: justify;
    font-size: 1.2em;
  }
  .nosotros-clientes-container h1 {
    font-size: 3em;
    padding: 0 10%;
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 320px) {
   /* SomosPV component */
   .nosotros-somospv-container h1 {
    font-size: 1.5em;
  }
  .margin-fix {
    margin-top: -20px;
  }
  .nosotros-somospv-container p {
    font-size: 1em;
    margin-top: 0;
  }
  /* SomosPV component */
  .somospv-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 30px;
    justify-content: center;
    align-content: center;
  }  
  .somos-pv-list-item img {
    width: 50%;
    height: 50%;
  }
  .somos-pv-list-item h2 {
    font-size: 1.5em;
    padding-top: 20px;
  }
  .somos-pv-list-item p{
    font-size: 1em;
  }
  .somos-pv-list-item ol li {
    font-size: 0.9em;
    width: 100%;
  }
  /* SomosPVNum component */
  .somospv-numeros-container h1 {
    font-size: 1.5em;
    text-align: center;
  }
  .somospv-numeros-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 20px;
  }
  .somospv-numeros-list-items h1 {
    font-size:1.5em;
  }
  .somospv-numeros-list-items p {
    font-size: 1em;
  }
  .Normas-container img {
    width: 80%;
    height: 80%;
  }
  .Normas-container p {
    font-size: 1em;
  }
  .nosotros-clientes-container h1 {
    font-size: 1.5em;
  }
  .nosotros-clientes-container img {
    width: 150%;
    height: 150%;
    padding: 20% 0;
  }
}
