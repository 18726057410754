/* Global Components */
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700|Raleway:400,700&display=swap");

/* fonts settings */
@font-face {
  font-family: CaviarDreams;
  src: url(../../assets/fonts/CaviarDreams/CaviarDreams.ttf);
}
@font-face {
  font-family: CaviarDreamsBold;
  src: url(../../assets/fonts/CaviarDreams/CaviarDreams_Bold.ttf);
}
body {
  margin: 0;
  background-color: #ffffff;
  color: #383838;
  font-family: "Raleway", sans-serif;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: lighter;
  font-size: 60px;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
}

h3 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #606060;
  font-size: 30px;
}

p {
  font-family: "Raleway", sans-serif;
  padding-bottom: 0.5em;
}


/* NotFound component */
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  height: auto;
}
.not-found img {
  max-width: 100%;
}
.not-found h1 {
  font-size: 50px;
}
.not-found p {
  margin-top: -20px;
  font-size: 20px;
}

/* Navbar componente */
.burguer-menu {
  width: 40px;
  height: 40px;
  display: none;
  /* Una forma de centrar con block correspondiente al height */
  /* line-height: 40px;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 5px;
  top: 5px; */
}
.navbar {
  background-color: #ffffff;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 80px;
  /* position: fixed; */
  /* border: 1px solid firebrick; */
}
.navbar-figure img {
  max-width: 100%;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #383838;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: bolder;
}
.navbar-menu {
  height: inherit;
}
.navbar ol {
  display: flex;
  height: inherit;
}
.navbar ol li {
  height: inherit;
}
.navbar-menu > ol > li > a{
  cursor: pointer;
}
.menu-item {
  display: flex;
  align-items: center;
  height: inherit;
  padding: 0 40px;
  text-decoration: none;
  color: #383838;
  border-bottom: 4px solid transparent;
}
.menu-item:hover {
  border-bottom: 4px solid #a00202;
  transition: border-bottom 0.5s ease-in-out;
}
.submenu{
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  padding: 15px 0 0 0;
  border-radius: 10px;
}
.submenu-item{
  display: flex;
  font-size: 0.8em;
  align-items: center;
  height: inherit;
  padding: 5px 40px;
  text-decoration: none;
  color: #383838;
  border-bottom: 4px solid transparent;
}
.submenu-item:hover {
  border-bottom: 3px solid #a00202;
  background-color: rgba(212, 73, 0, 0.2);
  transition: border-bottom 0.5s ease-in-out;
}

/* Footer componente */
.footer {
  background-color: #606060;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
  padding: 0 80px;
}
.footer-figure {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.footer-figure img {
  max-width: 100%;
  padding-bottom: 10px;
}
.footer ol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: inherit;
  padding: 0 40px;
  border-bottom: 4px solid transparent;
  height: inherit;
}
.footer ol li {
  padding: 0 35px;
  height: inherit;
}
.footer-item {
  text-decoration: none;
  font-weight: lighter;
  color: #ffffff;
  text-align: center;
}
.footer-item:hover {
  font-weight: bolder;
  transition: font-weight 0.5s ease-in-out;
}

/* Button component */

.button-more-info-s {
  display: inline-block;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 20px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-s:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-lg {
  display: inline-block;
  width: 1000px;
  text-align: center;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-lg:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-whatsapp {
  display: inline-block;
  margin: -20px 0 20px 20px;
  background: linear-gradient(#15FE2A, #04BB20);
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 15px 20px;
  border: 0;
  border-radius: 15px;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.button-more-info-whatsapp:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-xs{
  display: inline-block;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 15px 20px;
  border: 0;
  border-radius: 15px;
  text-decoration: none;
}
.button-more-info-xs:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

/* ScrollButton */
.scroll {
  opacity: 0.3;
  background-color: transparent;
  /* width: 60px;
  height: 60px; */
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  cursor: pointer;
  margin:0;
  padding: 0;
}
.scroll:hover {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
}

/* Modal style */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 5px;
  max-width: 500px;
  padding: 2rem 4rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}
.modal-body-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body-container h2{
  padding: 4px 10%;
  margin-bottom: 5%;
  border-bottom: 4px solid #a00202;
}
.modal-body-container p{
  margin-top:0;
  text-align: justify;
}
.modal-body-container ol{
  align-self: flex-start;
}
.modal-body-container ol li{
  font-size: 16px;
  margin: 0 0 10px 0;
}
.modal-body-container .btn-category .button-more-info-xs{
  margin: 10px 30px;
}

.modal-button-container{
 display: flex;
 flex-direction: row;   
}
.list-description{
  display: initial;
  font-weight: lighter;
}

button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #247BA0;
  color: #fff;
}

/* Media Query */

@media screen and (max-width: 1400px) {
  /* navbar */
  .navbar-figure img {
    max-height: 40px;
  }
  ol,
  ul {
    font-size: 1.1em;
  }
  .menu-item {
    padding: 0 10px;
  }
  
  /* Not Found */
  .not-found{
    height: auto;
  }
  .not-found img {
    max-width: 20%;
    height: 200px;
  }
  .not-found h1 {
    font-size: 3em;
  }
  .not-found p {
    font-size: 1.5em;
    margin-top: -20px;
    padding-bottom: 20px;
  }  
}

@media screen and (max-width: 1024px) {
  /* navbar */
  .navbar {
    justify-content: center;
    padding: 0 20px;
  }
  .menu-item {
    padding: 0 40px;
  }
  ol,
  ul {
    font-size: 1em;
  }

  /* Footer */
  .footer-figure {
    align-items: center;
  }
  .footer-figure img {
    max-width: 80%;
    padding-bottom: 10px;
  }
  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 150px;
  }
  .not-found h1 {
    margin-top: -2px;
  }
}

@media screen and (max-width: 768px) {
  /* NavBar */
  .navbar {
    justify-content: space-between;
    height: auto;
  }
  .navbar-figure{
    margin: 0;
    align-self: center;
    padding-top: 10px;
  }
  .navbar-figure img{
    max-height: 60px;
    align-self: center;
  }
  .burguer-menu {
    right: 5%;
    display: inline;
    z-index: 4;
  }
  .navbar-menu {
    position: fixed;
    /* Gradiente */
    background: #373b44; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #ffffffea,
      rgba(250, 251, 255, 0.925)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ffffffea,
      rgba(250, 251, 255, 0.925)
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 3;
    top: 0;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .submenu{
    position: inherit;
  }

  .navbar ol {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .navbar ol li{
    font-size: 30px;
    align-self: center;
    justify-self: center;
    text-align: center;
    height: auto;
    padding: 5% 0;
  }
  .navbar-menu.is-active {
    left: 0;
  }
  .navbar-figure img {
    max-height: 30px;
  }
  /* Footer */
  .footer ol {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .footer ol li {
    padding: 10px 0;
    height: inherit;
  }

  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 115px;
  }
  .not-found h1 {
    font-size: 2em;
    margin-top: 1px;
  }
  .not-found p {
    font-size: 1em;
  } 
}

@media screen and (max-width: 480px) {
  /* Footer */
  .footer{
    padding: 0;
  }
  .footer-figure img {
    width: 60%;
    height: 100%;
    padding-bottom: 10px;
  }
  .footer-figure span{
    text-align: center;
    font-size: .9em;
  }
  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 70px;
  }
  .not-found h1 {
    font-size: 1.5em;
  }
  .not-found p {
    font-size: .8em;
    margin-top: -15px;
  } 
  /* Modal */
  .modal-body-container h2{
    font-size: 2em;
  }
  .modal-body-container p{
    font-size: 0.9em;
  }
  .modal-body-container ol li{
    font-size: 0.9em;
  }
  .modal-body-container .btn-category .button-more-info-xs{
    text-align: center;
    font-size: 1em;
    width: 50vw;
  }
  .modal {
    width: 60vw;
    padding: 2rem 3rem;
  }
  .modal-button-container{
    display: flex;
    flex-direction: column;
   }
}

@media screen and (max-width: 320px) {
  /* footer */
  .footer-figure span {
    font-size: .7em;
  }
  .footer-item{
    font-size: .8em;
  }
  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 50px;
  }
  .not-found h1 {
    font-size: 1em;
  }
  .not-found p {
    font-size: .6em;
    margin-top: -10px;
  } 
    /* Modal */
    .modal-body-container h2{
      font-size: 1.5em;
    }
    .modal-body-container p{
      font-size: 0.8em;
    }
    .modal-body-container ol li{
      font-size: 0.8em;
    }
    .modal-body-container .btn-category .button-more-info-xs{
      text-align: center;
      font-size: 0.8em;
      width: 40vw;
    }
    .modal {
      width: 50vw;
    }
}
