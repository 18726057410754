@import url(https://fonts.googleapis.com/css?family=Montserrat:500,700|Raleway:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:500,700|Raleway:400,700&display=swap);
/* Global Components */

/* fonts settings */
@font-face {
  font-family: CaviarDreams;
  src: url(/static/media/CaviarDreams.174b0a13.ttf);
}
@font-face {
  font-family: CaviarDreamsBold;
  src: url(/static/media/CaviarDreams_Bold.1062ba91.ttf);
}
body {
  margin: 0;
  background-color: #ffffff;
  color: #383838;
  font-family: "Raleway", sans-serif;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: lighter;
  font-size: 60px;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
}

h3 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #606060;
  font-size: 30px;
}

p {
  font-family: "Raleway", sans-serif;
  padding-bottom: 0.5em;
}


/* NotFound component */
.not-found {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* height: 100vh; */
  height: auto;
}
.not-found img {
  max-width: 100%;
}
.not-found h1 {
  font-size: 50px;
}
.not-found p {
  margin-top: -20px;
  font-size: 20px;
}

/* Navbar componente */
.burguer-menu {
  width: 40px;
  height: 40px;
  display: none;
  /* Una forma de centrar con block correspondiente al height */
  /* line-height: 40px;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 5px;
  top: 5px; */
}
.navbar {
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  height: auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 80px;
  /* position: fixed; */
  /* border: 1px solid firebrick; */
}
.navbar-figure img {
  max-width: 100%;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #383838;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: bolder;
}
.navbar-menu {
  height: inherit;
}
.navbar ol {
  display: -webkit-flex;
  display: flex;
  height: inherit;
}
.navbar ol li {
  height: inherit;
}
.navbar-menu > ol > li > a{
  cursor: pointer;
}
.menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: inherit;
  padding: 0 40px;
  text-decoration: none;
  color: #383838;
  border-bottom: 4px solid transparent;
}
.menu-item:hover {
  border-bottom: 4px solid #a00202;
  transition: border-bottom 0.5s ease-in-out;
}
.submenu{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  padding: 15px 0 0 0;
  border-radius: 10px;
}
.submenu-item{
  display: -webkit-flex;
  display: flex;
  font-size: 0.8em;
  -webkit-align-items: center;
          align-items: center;
  height: inherit;
  padding: 5px 40px;
  text-decoration: none;
  color: #383838;
  border-bottom: 4px solid transparent;
}
.submenu-item:hover {
  border-bottom: 3px solid #a00202;
  background-color: rgba(212, 73, 0, 0.2);
  transition: border-bottom 0.5s ease-in-out;
}

/* Footer componente */
.footer {
  background-color: #606060;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
  padding: 0 80px;
}
.footer-figure {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}
.footer-figure img {
  max-width: 100%;
  padding-bottom: 10px;
}
.footer ol {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: inherit;
  padding: 0 40px;
  border-bottom: 4px solid transparent;
  height: inherit;
}
.footer ol li {
  padding: 0 35px;
  height: inherit;
}
.footer-item {
  text-decoration: none;
  font-weight: lighter;
  color: #ffffff;
  text-align: center;
}
.footer-item:hover {
  font-weight: bolder;
  transition: font-weight 0.5s ease-in-out;
}

/* Button component */

.button-more-info-s {
  display: inline-block;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 20px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-s:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-lg {
  display: inline-block;
  width: 1000px;
  text-align: center;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-lg:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-whatsapp {
  display: inline-block;
  margin: -20px 0 20px 20px;
  background: linear-gradient(#15FE2A, #04BB20);
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 15px 20px;
  border: 0;
  border-radius: 15px;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.button-more-info-whatsapp:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-xs{
  display: inline-block;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 15px 20px;
  border: 0;
  border-radius: 15px;
  text-decoration: none;
}
.button-more-info-xs:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

/* ScrollButton */
.scroll {
  opacity: 0.3;
  background-color: transparent;
  /* width: 60px;
  height: 60px; */
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  cursor: pointer;
  margin:0;
  padding: 0;
}
.scroll:hover {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
}

/* Modal style */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 5px;
  max-width: 500px;
  padding: 2rem 4rem;
}

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}
.modal-body-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.modal-body-container h2{
  padding: 4px 10%;
  margin-bottom: 5%;
  border-bottom: 4px solid #a00202;
}
.modal-body-container p{
  margin-top:0;
  text-align: justify;
}
.modal-body-container ol{
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.modal-body-container ol li{
  font-size: 16px;
  margin: 0 0 10px 0;
}
.modal-body-container .btn-category .button-more-info-xs{
  margin: 10px 30px;
}

.modal-button-container{
 display: -webkit-flex;
 display: flex;
 -webkit-flex-direction: row;
         flex-direction: row;   
}
.list-description{
  display: inline;
  display: initial;
  font-weight: lighter;
}

button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #247BA0;
  color: #fff;
}

/* Media Query */

@media screen and (max-width: 1400px) {
  /* navbar */
  .navbar-figure img {
    max-height: 40px;
  }
  ol,
  ul {
    font-size: 1.1em;
  }
  .menu-item {
    padding: 0 10px;
  }
  
  /* Not Found */
  .not-found{
    height: auto;
  }
  .not-found img {
    max-width: 20%;
    height: 200px;
  }
  .not-found h1 {
    font-size: 3em;
  }
  .not-found p {
    font-size: 1.5em;
    margin-top: -20px;
    padding-bottom: 20px;
  }  
}

@media screen and (max-width: 1024px) {
  /* navbar */
  .navbar {
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 20px;
  }
  .menu-item {
    padding: 0 40px;
  }
  ol,
  ul {
    font-size: 1em;
  }

  /* Footer */
  .footer-figure {
    -webkit-align-items: center;
            align-items: center;
  }
  .footer-figure img {
    max-width: 80%;
    padding-bottom: 10px;
  }
  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 150px;
  }
  .not-found h1 {
    margin-top: -2px;
  }
}

@media screen and (max-width: 768px) {
  /* NavBar */
  .navbar {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: auto;
  }
  .navbar-figure{
    margin: 0;
    -webkit-align-self: center;
            align-self: center;
    padding-top: 10px;
  }
  .navbar-figure img{
    max-height: 60px;
    -webkit-align-self: center;
            align-self: center;
  }
  .burguer-menu {
    right: 5%;
    display: inline;
    z-index: 4;
  }
  .navbar-menu {
    position: fixed;
    /* Gradiente */
    background: #373b44; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ffffffea,
      rgba(250, 251, 255, 0.925)
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 3;
    top: 0;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    transition: 0.3s;
  }

  .submenu{
    position: inherit;
  }

  .navbar ol {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .navbar ol li{
    font-size: 30px;
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
    text-align: center;
    height: auto;
    padding: 5% 0;
  }
  .navbar-menu.is-active {
    left: 0;
  }
  .navbar-figure img {
    max-height: 30px;
  }
  /* Footer */
  .footer ol {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
  }
  .footer ol li {
    padding: 10px 0;
    height: inherit;
  }

  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 115px;
  }
  .not-found h1 {
    font-size: 2em;
    margin-top: 1px;
  }
  .not-found p {
    font-size: 1em;
  } 
}

@media screen and (max-width: 480px) {
  /* Footer */
  .footer{
    padding: 0;
  }
  .footer-figure img {
    width: 60%;
    height: 100%;
    padding-bottom: 10px;
  }
  .footer-figure span{
    text-align: center;
    font-size: .9em;
  }
  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 70px;
  }
  .not-found h1 {
    font-size: 1.5em;
  }
  .not-found p {
    font-size: .8em;
    margin-top: -15px;
  } 
  /* Modal */
  .modal-body-container h2{
    font-size: 2em;
  }
  .modal-body-container p{
    font-size: 0.9em;
  }
  .modal-body-container ol li{
    font-size: 0.9em;
  }
  .modal-body-container .btn-category .button-more-info-xs{
    text-align: center;
    font-size: 1em;
    width: 50vw;
  }
  .modal {
    width: 60vw;
    padding: 2rem 3rem;
  }
  .modal-button-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
   }
}

@media screen and (max-width: 320px) {
  /* footer */
  .footer-figure span {
    font-size: .7em;
  }
  .footer-item{
    font-size: .8em;
  }
  /* Not Found */
  .not-found img {
    max-width: 20%;
    height: 50px;
  }
  .not-found h1 {
    font-size: 1em;
  }
  .not-found p {
    font-size: .6em;
    margin-top: -10px;
  } 
    /* Modal */
    .modal-body-container h2{
      font-size: 1.5em;
    }
    .modal-body-container p{
      font-size: 0.8em;
    }
    .modal-body-container ol li{
      font-size: 0.8em;
    }
    .modal-body-container .btn-category .button-more-info-xs{
      text-align: center;
      font-size: 0.8em;
      width: 40vw;
    }
    .modal {
      width: 50vw;
    }
}

/* fonts settings */
@font-face {
  font-family: CaviarDreams;
  src: url(/static/media/CaviarDreams.174b0a13.ttf);
}
@font-face {
  font-family: CaviarDreamsBold;
  src: url(/static/media/CaviarDreams_Bold.1062ba91.ttf);
}
body {
  margin: 0;
  background-color: #ffffff;
  color: #383838;
  font-family: "Raleway", sans-serif;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: lighter;
  font-size: 60px;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
}

h3 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #606060;
  font-size: 30px;
}

p {
  font-family: "Raleway", sans-serif;
}
/* Components from Homepage */
/* Componente Hero */
.home-hero {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: space-between;
          align-items: space-between;
  background-image: url(/static/media/back_insumos_carrousel.013f5f20.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 80px 40px 80px;
}
.home-hero > h1 {
  font-size: 30px;
  color: #383838;
  font-weight: lighter;
  /* #a00202cb */
  text-align: center;
  margin: 30px 200px;
}
.home-hero img {
  max-width: 100%;
}
.home-hero .carousel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}
.home-hero-shape-title {
  text-align: center;
  font-size: 30px;
  background-color: #a00202;
  color: #ffffff;
  border-radius: 15px;
  width: 200px;
  height: 70px;
}
.home-hero-shape-content {
  display: block;
  border-radius: 20px;
  background-color: #ffffff;
  color: #606060;
  font-size: 30px;
  width: 500px;
  padding: 10px 20px 15px 10px;
  margin-top: -22px;
}
.home-hero-shape-content p {
  text-align: justify;
  padding: 0px 40px 20px 20px;
  margin-bottom: 0;
}
.button-more-info-s {
  display: inline-block;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 20px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-s:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-lg {
  display: inline-block;
  width: 1000px;
  text-align: center;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-lg:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

/* component info of homepage */
.home-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #a00202;
  padding: 80px 0 80px 0;
}
.home-info p {
  color: #606060;
  text-align: center;
  font-size: 30px;
  padding: 0px 160px;
}
.home-info .fix-p-margin {
  margin-top: 20;
}
.home-list-category {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.home-list-category .category {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 200px;
  padding: 20px 50px;
}
.home-list-category a {
  text-decoration: none;
}
.category p {
  font-size: 20px;
  padding: 20px;
  margin-bottom: 0;
  font-weight: bold;
}
.category > img {
  padding-top: 20px;
}
.category:hover {
  background-color: #a0020217;
  border-bottom: solid 5px #a00202;
  transition: border-bottom 0.2s ease-in-out;
}

/* Component Slogan */
.home-slogan > h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  color: #383838;
  font-size: 80px !important;
  padding: 60px 0px;
}

/* component Categorias of homepage */

/* component infoHacemos of homepage */
.home-hacemos {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.home-hacemos-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #efefef;
  padding: 20px 0 20px 0;
  width: 100%;
  height: auto;
}
.home-hacemos p{
  text-align: justify;
  font-size: 1.5em;
  padding: 0vw 10vw;
}
.home-hacemos-list h2 {
  color: #a00202;
  padding-top: 20px;
  text-align: center;
}
.hacemos-category-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 10px;
  grid-column-gap: 140px;
  margin: 20px 0px;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.hacemos-category-item {
  font-weight: lighter;
  display: inline-block;
  -webkit-align-self: center;
          align-self: center;
  justify-self: stretch;
  font-size: 1.6rem;
}
.link-btn {
  margin-top: 40px;
}
/* component Envio of homepage */
.home-envio {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 80px 0 80px 0;
}
.envio-content {
  display: block;
  margin-left: 35px;
}
.envio-content h2 {
  color: #a00202;
  margin-top: 0;
}
.envio-content p {
  font-size: 20px;
  margin-top: -30px;
  max-width: 650px;
}

/* component ProductoCategoria of homepage */

.home-product-category {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  color: #a00202;
  margin-bottom: 80px;
  padding: 0 10%;
}
.home-product-category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.home-product-category-list {
  display: grid;
  grid-template-columns: repeat(2, 350px);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 20px;
  color: #606060;
  justify-items: start;
  margin-top: -65px;
}
.home-product-category-item ol {
  margin-top: -30px;
}
.home-product-category-item > h3 {
  text-align: left;
}
.home-product-category-item ol li {
  color: #606060;
  font-weight: 900;
  font-style: italic;
  font-size: 18px;
}
.btn-category {
  margin-left: -20px;
  margin-top: 35px;
}
.fix-h3 {
  font-size: 25px;
  margin: -30px 0 70px 0;
  color: #606060;
  max-width: 500px;
  text-align: left;
}
.home-product-category-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  color: #a00202;
  margin-bottom: 80px;
  padding: 0 10%;
}
.home-product-category-details-rows {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  justify-items: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.home-product-category img {
  padding-right: 2%;
}
.home-product-category-details-rows h2 {
  margin-bottom: 20px;
}
.home-product-category-item-rows h3 {
  text-align: left;
  margin-top: -10px;
}

.home-product-category-item-rows p {
  color: #606060;
  font-style: italic;
  max-width: 520px;
  font-size: 20px;
  margin: -20px 0 0 0;
  padding-bottom: 20px;
  color: #606060;
  font-weight: 900;
  font-size: 18px;
  text-align: justify;
}

/* ScrollButton */
.scroll {
  opacity: 0.3;
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  cursor: pointer;
}
.scroll:hover {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* Carrusel */
.slide-container {
  width: 90%;
  margin: auto;
}

h3 {
  text-align: center;
}
.each-slide {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}
.each-slide > a {
  text-decoration: none;
  width: 50%;
}
.each-slide > a > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center;
  margin-bottom: 20px;
}

.each-slide > a > span {
  font-size: 30px;
  font-weight: lighter;
  color: #a00202;
}

.each-fade {
  display: -webkit-flex;
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  background: #adceed;
}
/* botenes de abajo del carrusel */
.react-slideshow-container + div.indicators > div {
  width: 12px;
  height: 12px;
  background: rgb(160, 158, 158);
}
.react-slideshow-container + div.indicators > div.active {
  background: #a00202;
  transition: border 0.5s ease-in-out;
}
/* botenes de los extremos del carrusel */
.react-slideshow-container .nav {
  height: 60px;
  /* background: rgba(255, 255, 255, 0.6); */
  background: transparent;
  width: 60px;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}
.react-slideshow-container .nav:hover {
  background: transparent;
}
.react-slideshow-container .nav span {
  display: block;
  width: 20px;
  height: 20px;
  border-color: #a002027e;
  border-style: solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.react-slideshow-container .nav span:hover {
  border-color: #a00202;
  width: 25px;
  height: 25px;
  transition: border-color 0.5s ease-in-out;
}

/* Media Query */

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
  /* Hero */
  .home-hero h1 {
    font-size: 1.5em;
  }
  .each-slide > a > span {
    font-size: 1.5em;
  }
  .home-hacemos p{
    font-size: 1.2em;
  }
  /* Component Slogan*/
  .home-slogan h1 {
    font-size: 0.5em;
    margin-top: 0;
    padding-bottom: 3%;
  }
  .home-slogan .title-2 {
    margin-top: -10px;
  }
  /* component Envio of homepage */
  .home-envio img {
    width: 12%;
    height: 12%;
    margin-right: -2%;
  }
  .envio-content h2 {
    font-size: 2em;
  }
  .envio-content p {
    font-size: 1em;
    margin-top: -30px;
    max-width: 50vw;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-grid {
    grid-row-gap: 5px;
    grid-column-gap: 60px;
  }
  .home-hacemos-list {
    margin-bottom: 2%;
  }
  .home-hacemos-list h2 {
    font-size: 2em;
  }
  /* Info */
  .home-info h2 {
    font-size: 2em;
  }
  .home-info p {
    font-size: 1.5em;
    text-align: justify;
  }
  .category img {
    padding-top: 0;
    width: 50%;
    height: 50%;
  }
  .category p {
    text-align: center;
    font-size: 1.4em;
    margin-top: 0;
    padding-top: 0;
  }

  /* Producto Category */
  .home-product-category img {
    height: 50vw;
    padding-right: 2%;
  }
  .home-product-category-details h2 {
    font-size: 2em;
  }
  .home-product-category-item h3 {
    font-size: 1.5em;
    margin-top: 20%;
  }
  .fix-h3 {
    font-size: 1.6em;
  }
  .home-product-category-list {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    grid-row-gap: 0;
    grid-column-gap: 10px;
    color: #606060;
    justify-items: start;
    margin-top: -65px;
  }

  .home-product-category-row {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .home-product-category-details-rows {
    -webkit-align-items: center;
            align-items: center;
    width: 80%;
  }
  .home-product-category-details-rows {
    width: 38%;
  }
  .home-product-category-details-rows h2 {
    font-size: 2em;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .home-product-category-item-rows h3 {
    font-size: 1.5em;
    margin-top: -10px;
  }
  /* Scroll */
  .scroll img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  /* Hero */
  .home-hero h1 {
    font-size: 1.2em;
    margin: 0;
  }
  .each-slide > a > span {
    font-size: 1.2em;
  }
  .home-hacemos p{
    font-size: 1.1em;
  }
  /* Envio Component */
  .home-envio img {
    width: 15%;
    height: 15%;
    margin-right: -1%;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-grid {
    grid-row-gap: 5px;
    grid-column-gap: 10px;
  }
  .home-hacemos-list h2 {
    font-size: 1.6em;
  }
  .hacemos-category-item {
    font-size: 1em;
  }
  /* Info */
  .home-info {
    padding: 5% 0;
  }
  .home-info h2 {
    font-size: 1.6em;
  }
  .home-info p {
    font-size: 1em;
  }
  .category img {
    padding-top: 0;
    width: 50%;
    height: 50%;
  }
  .category p {
    font-size: 1.2em;
  }
  /* Producto Category */
  .home-product-category {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .home-product-category img {
    height: 50vw;
  }
  .home-product-category-details {
    -webkit-align-items: center;
            align-items: center;
  }
  .home-product-category-details h2 {
    font-size: 1.6em;
    padding-top: 5%;
  }
  .home-product-category-item h3 {
    font-size: 1.4em;
    margin-top: 24%;
    margin-bottom: 15%;
  }
  .fix-h3 {
    font-size: 1.4em;
    margin-top: -4%;
    margin-bottom: 10%;
    text-align: justify;
  }
  .home-product-category-item ol li {
    font-size: 1.2em;
  }
  .btn-category .button-more-info-s {
    font-size: 1em;
  }

  .home-product-category-row {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .home-product-category-row img {
    height: 50vw;
  }
  .home-product-category-details-rows {
    -webkit-align-items: center;
            align-items: center;
    width: 80%;
  }
  .home-product-category-details-rows h2 {
    font-size: 1.6em;
    padding-top: 5%;
  }
  .home-product-category-item-rows h3 {
    font-size: 1.4em;
    margin-top: 5%;
  }
  .home-product-category-item-rows p {
    font-size: 1.2em;
  }
  .btn-category .button-more-info-s {
    margin: 0;
  }
  /* Scroll */
  .scroll {
    width: 30px;
    height: 30px;
    bottom: 30px;
    right: 20px;
  }
}

@media screen and (max-width: 480px) {
  /* Hero */
  .home-hero h1 {
    font-size: 0.9em;
    margin: 0;
  }
  .each-slide > a > span {
    font-size: 0.9em;
  }
  .home-hacemos p{
    font-size: 1em;
  }
  /* Component Slogan*/
  .home-slogan h1 {
    font-size: 0.25em;
    padding-bottom: 0;
  }
  /* Envio Component */
  .home-envio img {
    width: 18%;
    height: 18%;
    margin-right: -4%;
  }
  .envio-content h2 {
    font-size: 1.4em;
    margin-bottom: 12%;
  }
  .envio-content p {
    font-size: 0.7em;
    margin-top: -30px;
    max-width: 50vw;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-item {
    font-weight: lighter;
    display: inline-block;
    -webkit-align-self: center;
            align-self: center;
    justify-self: stretch;
    font-size: 20px;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-grid {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(1, auto);
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .hacemos-category-grid {
    grid-row-gap: 5px;
    grid-column-gap: 10px;
  }
  .home-hacemos-list h2 {
    font-size: 1.4em;
  }
  .hacemos-category-item {
    font-size: 0.8em;
  }
  /* Info */
  .home-info h2 {
    font-size: 1.4em;
  }
  .home-info p {
    font-size: 0.8em;
    padding: 0 10%;
  }
  .category img {
    padding-top: 0;
    width: 60%;
    height: 60%;
  }
  .category p {
    font-size: 1em;
  }
  /* Producto Category */
  .home-product-category-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .home-product-category {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #a00202;
    margin-bottom: 80px;
  }
  .home-product-category-details h2 {
    font-size: 1.4em;
    padding-top: 5%;
  }
  .home-product-category-item h3 {
    font-size: 1em;
    margin-top: 30%;
    margin-bottom: 20%;
  }
  .home-product-category-item ol li {
    font-size: 0.8em;
  }
  .btn-category .button-more-info-s {
    font-size: 0.8em;
    margin: 0;
  }
  .fix-h3 {
    font-size: 1em;
    width: 80%;
  }
  .home-product-category-details-rows h2 {
    font-size: 1.4em;
    margin-bottom: 2%;
  }
  .home-product-category-item-rows h3 {
    font-size: 1em;
    margin-top: 0;
  }
  .home-product-category-item-rows p {
    font-size: 0.8em;
  }
  /* Scroll */
  .scroll {
    width: 30px;
    height: 30px;
    bottom: 20px;
    right: 10px;
  }
  .scroll img {
    width: 15px;
    height: 15px;
  }
    /* Carrusel */
    .each-slide > a {
      text-decoration: none;
      width: 100%;
    }
    .each-slide > a > div {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 300px;
      background-position: center;
      margin-bottom: 20px;
      padding: -20px;
    }
  
    .each-fade {
      display: -webkit-flex;
      display: flex;
    }
  
    .each-fade .image-container {
      width: 100%;
      overflow: hidden;
    }
  
    .each-fade .image-container img {
      width: 100%;
    }
  
    .each-fade h2 {
      width: 25%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin: 0;
      background: #adceed;
    }
    .react-slideshow-container{
      width: 60vw;
    }
}

@media screen and (max-width: 320px) {
  /* Hero */
  .home-hero h1 {
    text-align: justify;
  }
  /* Component Slogan*/
  .home-slogan h1 {
    font-size: 0.21em;
    padding-bottom: 10px;
  }
  .home-hacemos p{
    font-size: 0.8em;
  }
  .home-slogan .title-2 {
    margin-top: -60px;
  }
  /* Envio Component */
  .home-envio img {
    width: 20%;
    height: 20%;
    margin-right: -6%;
  }
  .envio-content h2 {
    font-size: 1em;
    margin-bottom: 18%;
  }
  .envio-content p {
    font-size: 0.6em;
    margin-top: -30px;
    max-width: 50vw;
  }
  /* component infoHacemos of homepage */
  .home-hacemos-list h2 {
    font-size: 1.2em;
  }
  .hacemos-category-item {
    font-size: 0.8em;
  }
  /* Info */
  .home-info h2 {
    font-size: 1.2em;
  }
  /*Productos categorias  */
  .home-product-category-details h2 {
    text-align: center;
  }
  .fix-h3 {
    font-size: 0.9em;
    padding-bottom: 5%;
  }
}

/* Components from nosotros */

/* SomosPV component */
.nosotros-somospv-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 10%;
}
.nosotros-somospv-container h1 {
  font-weight: bolder;
  color: #a00202;
}
.nosotros-somospv-container span {
  color: #383838;
}
.margin-fix {
  margin-top: -40px;
}
.nosotros-somospv-container p {
  text-align: justify;
  font-size: 28px;
  color: #606060;
  font-family: "Montserrat", sans-serif;
}
.somospv-list {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-column-gap: 80px;
  justify-items: center;
  -webkit-align-items: start;
          align-items: start;
  margin-top: 70px;
}
.somos-pv-list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.somos-pv-list-item h2 {
  color: #a00202;
  padding-top: 60px;
}
.somos-pv-list-item p {
  font-size: 20px;
  padding: 0 10%;
  margin-top: 0;
}
.somos-pv-list-item ol li {
  font-size: 20px;
  text-align: justify;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 5px;
  color: #606060;
}

/* SomosPVNum component */
.somospv-numeros-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 10%;
  padding-top: 20px;
}
.somospv-numeros-container h1 {
  font-weight: bolder;
  color: #383838;
}
.somospv-numeros-container span {
  color: #a00202;
}
.somospv-numeros-list {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 100px;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  padding-bottom: 80px;
}
.somospv-numeros-list-items h1 {
  color: #a00202;
  font-size: 120px;
  font-weight: 700;
  margin: 0;
  text-align: center;
}
.somospv-numeros-list-items p {
  font-size: 30px;
  text-align: center;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  color: #606060;
  margin: 0;
}

/*Normas component*/
.Normas-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 10%;
}
.Normas-container p {
  font-style: italic;
  font-size: 28px;
  padding-left: 20px;
}

/* Clientes component */
.nosotros-clientes-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 100px;
}
.nosotros-clientes-container h1 {
  font-weight: 700;
  text-align: center;
}
.nosotros-clientes-item {
  padding: 50px 20px;
}

/* Media Query */

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
  /* SomosPV component */
  .somospv-list {
    grid-column-gap: 40px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
  }

  /* SomosPVNum component */
  .somospv-numeros-list {
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 10%;
  }
  .somospv-numeros-list-items h1 {
    font-size:5em;
  }
  .somospv-numeros-list-items p {
    font-size: 2em;
  }
}

@media screen and (max-width: 768px) {

  /* SomosPV component */
  .nosotros-somospv-container h1 {
    text-align: center;
    font-size: 3em;
  }
  .nosotros-somospv-container p {
    text-align: justify;
    font-size: 1.2em;
  }
  /* SomosPV component */
  .somospv-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 30px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
  }
  .somos-pv-list-item h2 {
    font-size: 2em;
  }
  .somos-pv-list-item p{
    font-size: 1.2em;
    width: 60vw;
  }
  /* SomosPVNum component */
  .somospv-numeros-container h1 {
    font-size: 3em;
    text-align: center;
  }
  .somospv-numeros-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 20px;
  }
  .somospv-numeros-list-items h1 {
    font-size:3.5em;
  }
  .somospv-numeros-list-items p {
    font-size: 1.8em;
  }
  .Normas-container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .Normas-container p {
    text-align: justify;
    font-size: 1.2em;
  }
  .nosotros-clientes-container h1 {
    font-size: 3em;
    padding: 0 10%;
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 320px) {
   /* SomosPV component */
   .nosotros-somospv-container h1 {
    font-size: 1.5em;
  }
  .margin-fix {
    margin-top: -20px;
  }
  .nosotros-somospv-container p {
    font-size: 1em;
    margin-top: 0;
  }
  /* SomosPV component */
  .somospv-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 30px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
  }  
  .somos-pv-list-item img {
    width: 50%;
    height: 50%;
  }
  .somos-pv-list-item h2 {
    font-size: 1.5em;
    padding-top: 20px;
  }
  .somos-pv-list-item p{
    font-size: 1em;
  }
  .somos-pv-list-item ol li {
    font-size: 0.9em;
    width: 100%;
  }
  /* SomosPVNum component */
  .somospv-numeros-container h1 {
    font-size: 1.5em;
    text-align: center;
  }
  .somospv-numeros-list {
    grid-template-columns: repeat(1, 50%);
    grid-row-gap: 20px;
  }
  .somospv-numeros-list-items h1 {
    font-size:1.5em;
  }
  .somospv-numeros-list-items p {
    font-size: 1em;
  }
  .Normas-container img {
    width: 80%;
    height: 80%;
  }
  .Normas-container p {
    font-size: 1em;
  }
  .nosotros-clientes-container h1 {
    font-size: 1.5em;
  }
  .nosotros-clientes-container img {
    width: 150%;
    height: 150%;
    padding: 20% 0;
  }
}

/* Components from contacto */
.contacto-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  padding: 3% 10% 5% 10%;
}
.contacto-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-bottom: 60px;
}

/* Asesorias component */
.contacto-asesorias-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* flex-grow: 0; */
  width: 100%;
}
.contacto-asesorias-container h2 {
  color: #a00202;
  margin-bottom: 15px;
}
.contacto-asesorias-container p {
  font-size: 21px;
  text-align: justify;
  font-weight: lighter;
  font-family: "Montserrat", sans-serif;
  color: #606060;
  margin: 0;
  width: 85%;
  word-spacing: 2px;
}
.contacto-asesorias-container-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
  width: auto;
}
.contacto-asesorias-container input {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  border: none;
  background-color: #e7e7e7;
  padding: 20px;
  width: 350px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.contacto-asesorias-container textarea {
  resize: none;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  border: none;
  background-color: #e7e7e7;
  padding: 20px;
  width: 600px;
  height: 120px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.contacto-asesorias-container form .btn-category {
  margin: 0;
  margin-top: 20px;
  padding: 0;
}
.contacto-asesorias-container form .btn-category .button-more-info-s {
  margin: 0;
  width: 100px;
  text-align: center;
}

/* Contactanos component */
.contacto-contactanos-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  /* flex-grow: 1; */
  width: 60%;
  padding-top: 10%;
  padding-left: 50px;

  border-left: 2px solid #a00202;
}
.contacto-contactanos-container h2 {
  color: #a00202;
  margin-bottom: 10px;
}
.buttom-h2 {
  padding-bottom: 40px;
}
.contactanos-medio {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-bottom: 20px;
}
.p-items {
  display: inline-block;
}
.p-items p {
  margin: 0;
  padding-left: 15px;
}
.contactanos-medio p {
  margin: 0;
  padding-left: 15px;
}
.contactanos-medio p > a {
  text-decoration: none;
  color: #606060;
}
.contactanos-medio p > a:hover {
  text-decoration:underline;
  color: #a00202;
  font-weight: bolder;
}
/* frame component */
.embed-container {
  width: 100%;
  height: 0;
  padding-top: 25%; /* (heigth*100)/width */
  position: relative;
}
.embed-iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Query */

@media screen and (max-width: 1400px) {
  
}

@media screen and (max-width: 1024px) {

  /* contancto */
  .contacto-data{
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap
  }

  /* Asesorias contacto */
  .contacto-asesorias-container p{
    width: 80vw;
  }
  .contacto-asesorias-container h2{
    -webkit-align-self: center;
            align-self: center;
  }
  .contacto-asesorias-container form{
    -webkit-align-self: center;
            align-self: center;
  }
  .contacto-asesorias-container input{
    -webkit-align-self: center;
            align-self: center;
    width: 70vw;
  }
  .contacto-asesorias-container textarea{
    -webkit-align-self: center;
            align-self: center;
    width: 70vw;
  }
  .contacto-asesorias-container form .btn-category{
    -webkit-align-self: center;
            align-self: center;
  }
  .contacto-asesorias-container form .btn-category .button-more-info-s{
    width: 60vw;
  }
  
    /* Contactanos component */
  .contacto-contactanos-container {
    -webkit-align-self: center;
            align-self: center;
    border:none;
    width: 80vw;
    padding-left: 0;
  }
  .contacto-contactanos-container h2{
    -webkit-align-self: center;
            align-self: center;
    text-align: center;
  } 
  .contactanos-medio{
    width: 30%;
    -webkit-align-self: center;
            align-self: center;
    justify-self: flex-start;
  }
  .contacto-contactanos-container .btn-category{
    -webkit-align-self: center;
            align-self: center;
    padding: 0;
  }
  .contacto-contactanos-container .btn-category .button-more-info-whatsapp{
    margin: 0;
  }

}

@media screen and (max-width: 768px) {
   /* Asesorias contacto */
   .contacto-asesorias-container p{
    font-size: 1.2em;
  }
  .contacto-asesorias-container h2{
    font-size: 1.8em;
  }
  .contacto-asesorias-container input{
    font-size: 0.8em;
  }
  .contacto-asesorias-container textarea{
    font-size: 0.8em;
  }
  .contacto-asesorias-container form .btn-category .button-more-info-s{
    font-size: 1em;
  }

   /* Contactanos component */
  .contacto-contactanos-container h2{
    font-size: 1.8em;
  } 
  .contactanos-medio{
    width: 50%;
  }
  .contacto-contactanos-container .btn-category .button-more-info-whatsapp{
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  
}

@media screen and (max-width: 320px) {
   /* Asesorias contacto */
   .contacto-asesorias-container p{
    font-size: 0.9em;
  }
  .contacto-asesorias-container h2{
    font-size: 1.4em;
  }
  .contacto-asesorias-container input{
    font-size: 0.6em;
  }
  .contacto-asesorias-container textarea{
    font-size: 0.6em;
  }
  .contacto-asesorias-container form .btn-category .button-more-info-s{
    font-size: 0.9em;
  }

   /* Contactanos component */
   .contacto-contactanos-container h2{
    font-size: 1.4em;
  } 
  .contactanos-medio{
    width: 50%;
  }
  .p-items p{
    font-size: 0.8em;
  }
  .contactanos-medio p{
    font-size: 0.8em;
  }
  .contactanos-medio p > a{
    font-size: 0.8em;
  }
}

/* ProductoInsumo */
/* HeroCategory component */
.hero-category-comercial {
  height: 50vh;
  background-image: url(/static/media/dibal_header.5f34df8b.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-category-industria {
  height: 50vh;
  background-image: url(/static/media/IndicadorPlataforma.0d6f416c.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-category-insumos {
  height: 50vh;
  background-image: url(/static/media/GeneralEtiquetas.c6a5c352.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.insumo-hero-title {
  background-color: #a00202;
  color: #ffffff;
  font-size: 20px;
  width: auto;
  text-align: center;
  position: absolute;
  left: 3%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  padding: 10px 15px;
}

/* InsumoCategory components */
.category-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 0 10%;
}
.category-container h2 {
  color: #a00202;
  margin-top: 40px;
}
.category-container h3 {
  font-size: 1.6em;
  text-align: left;
  transition: font-size 0.5s;
  transition: font-size 0.5s;
}
.category-container h3:hover{
  font-size: 1.7em;
}
.category-container h4{
  font-size: 1.5em;
  -webkit-align-self: center;
          align-self: center;
  color: #a00202;
}
.category-container p {
  margin-top: -10px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
}
.category-container p,center{
  -webkit-align-self: center;
          align-self: center;
}
.category-container table, th, td{
  border: 2px solid #a00202;
  border-collapse: collapse;
  padding: 5px 10px;
  -webkit-align-self: center;
          align-self: center;
  margin: 10px 0 30px 0;
}
.category-interact-container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
.category-subsection-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.category-subsection-container h3{
  margin: 7px 0px;
}
.category-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-self: center;
          align-self: center;
  padding: 40px 0;
}
.category-list a {
  margin: 10px 20px;
  text-decoration: none;
}
.category-list p{
  margin: 5px 4vw;
}
.category-item {
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
  background-color: #a00202;
  color: #ffffff;
  margin: 5%;
  width: 140px;
  height: 140px;
  padding: 20px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.category-item:hover {
  border: 10px solid #6d0606;
  transition: border 0.5s ease-in-out;
}
.category-item:active {
  box-shadow: none;
  transition: box-shadow 0.5 ease-in-out;
  text-decoration: none;
}
.category-item:focus {
  border: 10px solid #6d0606;
  outline: none;
}

/* InsumoItem component*/
.item-container {
  /*align-self: center;*/
  -webkit-justify-content: center;
          justify-content: center;
  border-left: 4px solid #a00202;
  transition: border-top 0.5 ease-in-out;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  padding: 60px;
}
.item-container img {
  width: 400px;
  border-radius: 20px;
}
.item-container .btn-category {
  margin: 0;
  padding: 0;
  padding-top: 40px;
  -webkit-align-self: center;
          align-self: center;
}
.item-container .btn-category .button-more-info-xs {
  margin: 0;
}

/* Media Query */

@media screen and (max-width: 1400px) {
  .hero-category-comercial {
    height: 45vh;
  }
  .hero-category-industria {
    height: 45vh;
  }
  .hero-category-insumos {
    height: 45vh;
  }
}

@media screen and (max-width: 1024px) {
  .hero-category-comercial {
    height: 30vh;
  }
  .hero-category-industria {
    height: 30vh;
  }
  .hero-category-insumos {
    height: 30vh;
  }
}

@media screen and (max-width: 768px) {
  .hero-category-comercial {
    height: 25vh;
  }
  .hero-category-industria {
    height: 25vh;
  }
  .hero-category-insumos {
    height: 25vh;
  }
  /* HeroCategory component */
  .insumo-hero-title {
    font-size: 0.8em;
  }
  /* InsumoCategory components */
  .category-container h2 {
    font-size: 2em;
  }
  .category-container p {
    margin-top: -10px;
    font-size: 0.9em;
  }
  .category-item {
    font-size: 0.8em;
    margin: 5% 5%;
    width: 80px;
    height: 80px;
    padding: 20px;
    border-radius: 50%;
  }
  .category-list a {
    margin: 5% 5%;
  }
  .item-container {
    -webkit-align-self: center;
            align-self: center;
    width: 60vw;
    padding: 60px 30px 50px 30px;
    border-left: none;
    border-top: 4px solid #a00202;
  }
  .item-container img {
    width: 60vw;
    border-radius: 20px;
  }
  .item-container .btn-category {
    margin: 0;
    padding: 0;
    padding-top: 40px;
    -webkit-align-self: center;
            align-self: center;
  }
  .item-container .btn-category .button-more-info-xs {
    width: 40vw;
    text-align: center;
    font-size: 1em;
  }
  .category-interact-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 320px) {
  /* HeroCategory component */
  .insumo-hero-title {
    font-size: 0.6em;
  }
  /* InsumoCategory components */
  .category-container h2 {
    font-size: 1.5em;
  }
  .category-container p {
    font-size: 0.8em;
  }
  .category-item {
    font-size: 0.6em;
    width: 70px;
    height: 70px;
  }
  .item-container .btn-category .button-more-info-xs {
    font-size: 0.8em;
  }
}

