@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700|Raleway:400,700&display=swap");

/* fonts settings */
@font-face {
  font-family: CaviarDreams;
  src: url(../../assets/fonts/CaviarDreams/CaviarDreams.ttf);
}
@font-face {
  font-family: CaviarDreamsBold;
  src: url(../../assets/fonts/CaviarDreams/CaviarDreams_Bold.ttf);
}
body {
  margin: 0;
  background-color: #ffffff;
  color: #383838;
  font-family: "Raleway", sans-serif;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: lighter;
  font-size: 60px;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
}

h3 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #606060;
  font-size: 30px;
}

p {
  font-family: "Raleway", sans-serif;
}
/* Components from Homepage */
/* Componente Hero */
.home-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  background-image: url("../../assets/images/home/back_insumos_carrousel.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 80px 40px 80px;
}
.home-hero > h1 {
  font-size: 30px;
  color: #383838;
  font-weight: lighter;
  /* #a00202cb */
  text-align: center;
  margin: 30px 200px;
}
.home-hero img {
  max-width: 100%;
}
.home-hero .carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.home-hero-shape-title {
  text-align: center;
  font-size: 30px;
  background-color: #a00202;
  color: #ffffff;
  border-radius: 15px;
  width: 200px;
  height: 70px;
}
.home-hero-shape-content {
  display: block;
  border-radius: 20px;
  background-color: #ffffff;
  color: #606060;
  font-size: 30px;
  width: 500px;
  padding: 10px 20px 15px 10px;
  margin-top: -22px;
}
.home-hero-shape-content p {
  text-align: justify;
  padding: 0px 40px 20px 20px;
  margin-bottom: 0;
}
.button-more-info-s {
  display: inline-block;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 20px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-s:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

.button-more-info-lg {
  display: inline-block;
  width: 1000px;
  text-align: center;
  margin: 10px 0 20px 20px;
  background: linear-gradient(#ff1515, #cd041e);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}
.button-more-info-lg:hover {
  box-shadow: 0 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0, -1px);
  transition: border-shadow 0.5s ease-in-out;
}

/* component info of homepage */
.home-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #a00202;
  padding: 80px 0 80px 0;
}
.home-info p {
  color: #606060;
  text-align: center;
  font-size: 30px;
  padding: 0px 160px;
}
.home-info .fix-p-margin {
  margin-top: 20;
}
.home-list-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.home-list-category .category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 20px 50px;
}
.home-list-category a {
  text-decoration: none;
}
.category p {
  font-size: 20px;
  padding: 20px;
  margin-bottom: 0;
  font-weight: bold;
}
.category > img {
  padding-top: 20px;
}
.category:hover {
  background-color: #a0020217;
  border-bottom: solid 5px #a00202;
  transition: border-bottom 0.2s ease-in-out;
}

/* Component Slogan */
.home-slogan > h1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #383838;
  font-size: 80px !important;
  padding: 60px 0px;
}

/* component Categorias of homepage */

/* component infoHacemos of homepage */
.home-hacemos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-hacemos-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  padding: 20px 0 20px 0;
  width: 100%;
  height: auto;
}
.home-hacemos p{
  text-align: justify;
  font-size: 1.5em;
  padding: 0vw 10vw;
}
.home-hacemos-list h2 {
  color: #a00202;
  padding-top: 20px;
  text-align: center;
}
.hacemos-category-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 10px;
  grid-column-gap: 140px;
  margin: 20px 0px;
  align-content: center;
  justify-content: center;
}
.hacemos-category-item {
  font-weight: lighter;
  display: inline-block;
  align-self: center;
  justify-self: stretch;
  font-size: 1.6rem;
}
.link-btn {
  margin-top: 40px;
}
/* component Envio of homepage */
.home-envio {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0 80px 0;
}
.envio-content {
  display: block;
  margin-left: 35px;
}
.envio-content h2 {
  color: #a00202;
  margin-top: 0;
}
.envio-content p {
  font-size: 20px;
  margin-top: -30px;
  max-width: 650px;
}

/* component ProductoCategoria of homepage */

.home-product-category {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color: #a00202;
  margin-bottom: 80px;
  padding: 0 10%;
}
.home-product-category-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home-product-category-list {
  display: grid;
  grid-template-columns: repeat(2, 350px);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 20px;
  color: #606060;
  justify-items: start;
  margin-top: -65px;
}
.home-product-category-item ol {
  margin-top: -30px;
}
.home-product-category-item > h3 {
  text-align: left;
}
.home-product-category-item ol li {
  color: #606060;
  font-weight: 900;
  font-style: italic;
  font-size: 18px;
}
.btn-category {
  margin-left: -20px;
  margin-top: 35px;
}
.fix-h3 {
  font-size: 25px;
  margin: -30px 0 70px 0;
  color: #606060;
  max-width: 500px;
  text-align: left;
}
.home-product-category-row {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color: #a00202;
  margin-bottom: 80px;
  padding: 0 10%;
}
.home-product-category-details-rows {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
}
.home-product-category img {
  padding-right: 2%;
}
.home-product-category-details-rows h2 {
  margin-bottom: 20px;
}
.home-product-category-item-rows h3 {
  text-align: left;
  margin-top: -10px;
}

.home-product-category-item-rows p {
  color: #606060;
  font-style: italic;
  max-width: 520px;
  font-size: 20px;
  margin: -20px 0 0 0;
  padding-bottom: 20px;
  color: #606060;
  font-weight: 900;
  font-size: 18px;
  text-align: justify;
}

/* ScrollButton */
.scroll {
  opacity: 0.3;
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  cursor: pointer;
}
.scroll:hover {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* Carrusel */
.slide-container {
  width: 90%;
  margin: auto;
}

h3 {
  text-align: center;
}
.each-slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.each-slide > a {
  text-decoration: none;
  width: 50%;
}
.each-slide > a > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center;
  margin-bottom: 20px;
}

.each-slide > a > span {
  font-size: 30px;
  font-weight: lighter;
  color: #a00202;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}
/* botenes de abajo del carrusel */
.react-slideshow-container + div.indicators > div {
  width: 12px;
  height: 12px;
  background: rgb(160, 158, 158);
}
.react-slideshow-container + div.indicators > div.active {
  background: #a00202;
  transition: border 0.5s ease-in-out;
}
/* botenes de los extremos del carrusel */
.react-slideshow-container .nav {
  height: 60px;
  /* background: rgba(255, 255, 255, 0.6); */
  background: transparent;
  width: 60px;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
}
.react-slideshow-container .nav:hover {
  background: transparent;
}
.react-slideshow-container .nav span {
  display: block;
  width: 20px;
  height: 20px;
  border-color: #a002027e;
  border-style: solid;
  transform: rotate(45deg);
}
.react-slideshow-container .nav span:hover {
  border-color: #a00202;
  width: 25px;
  height: 25px;
  transition: border-color 0.5s ease-in-out;
}

/* Media Query */

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1024px) {
  /* Hero */
  .home-hero h1 {
    font-size: 1.5em;
  }
  .each-slide > a > span {
    font-size: 1.5em;
  }
  .home-hacemos p{
    font-size: 1.2em;
  }
  /* Component Slogan*/
  .home-slogan h1 {
    font-size: 0.5em;
    margin-top: 0;
    padding-bottom: 3%;
  }
  .home-slogan .title-2 {
    margin-top: -10px;
  }
  /* component Envio of homepage */
  .home-envio img {
    width: 12%;
    height: 12%;
    margin-right: -2%;
  }
  .envio-content h2 {
    font-size: 2em;
  }
  .envio-content p {
    font-size: 1em;
    margin-top: -30px;
    max-width: 50vw;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-grid {
    grid-row-gap: 5px;
    grid-column-gap: 60px;
  }
  .home-hacemos-list {
    margin-bottom: 2%;
  }
  .home-hacemos-list h2 {
    font-size: 2em;
  }
  /* Info */
  .home-info h2 {
    font-size: 2em;
  }
  .home-info p {
    font-size: 1.5em;
    text-align: justify;
  }
  .category img {
    padding-top: 0;
    width: 50%;
    height: 50%;
  }
  .category p {
    text-align: center;
    font-size: 1.4em;
    margin-top: 0;
    padding-top: 0;
  }

  /* Producto Category */
  .home-product-category img {
    height: 50vw;
    padding-right: 2%;
  }
  .home-product-category-details h2 {
    font-size: 2em;
  }
  .home-product-category-item h3 {
    font-size: 1.5em;
    margin-top: 20%;
  }
  .fix-h3 {
    font-size: 1.6em;
  }
  .home-product-category-list {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    grid-row-gap: 0;
    grid-column-gap: 10px;
    color: #606060;
    justify-items: start;
    margin-top: -65px;
  }

  .home-product-category-row {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .home-product-category-details-rows {
    align-items: center;
    width: 80%;
  }
  .home-product-category-details-rows {
    width: 38%;
  }
  .home-product-category-details-rows h2 {
    font-size: 2em;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .home-product-category-item-rows h3 {
    font-size: 1.5em;
    margin-top: -10px;
  }
  /* Scroll */
  .scroll img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  /* Hero */
  .home-hero h1 {
    font-size: 1.2em;
    margin: 0;
  }
  .each-slide > a > span {
    font-size: 1.2em;
  }
  .home-hacemos p{
    font-size: 1.1em;
  }
  /* Envio Component */
  .home-envio img {
    width: 15%;
    height: 15%;
    margin-right: -1%;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-grid {
    grid-row-gap: 5px;
    grid-column-gap: 10px;
  }
  .home-hacemos-list h2 {
    font-size: 1.6em;
  }
  .hacemos-category-item {
    font-size: 1em;
  }
  /* Info */
  .home-info {
    padding: 5% 0;
  }
  .home-info h2 {
    font-size: 1.6em;
  }
  .home-info p {
    font-size: 1em;
  }
  .category img {
    padding-top: 0;
    width: 50%;
    height: 50%;
  }
  .category p {
    font-size: 1.2em;
  }
  /* Producto Category */
  .home-product-category {
    flex-wrap: wrap;
  }
  .home-product-category img {
    height: 50vw;
  }
  .home-product-category-details {
    align-items: center;
  }
  .home-product-category-details h2 {
    font-size: 1.6em;
    padding-top: 5%;
  }
  .home-product-category-item h3 {
    font-size: 1.4em;
    margin-top: 24%;
    margin-bottom: 15%;
  }
  .fix-h3 {
    font-size: 1.4em;
    margin-top: -4%;
    margin-bottom: 10%;
    text-align: justify;
  }
  .home-product-category-item ol li {
    font-size: 1.2em;
  }
  .btn-category .button-more-info-s {
    font-size: 1em;
  }

  .home-product-category-row {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .home-product-category-row img {
    height: 50vw;
  }
  .home-product-category-details-rows {
    align-items: center;
    width: 80%;
  }
  .home-product-category-details-rows h2 {
    font-size: 1.6em;
    padding-top: 5%;
  }
  .home-product-category-item-rows h3 {
    font-size: 1.4em;
    margin-top: 5%;
  }
  .home-product-category-item-rows p {
    font-size: 1.2em;
  }
  .btn-category .button-more-info-s {
    margin: 0;
  }
  /* Scroll */
  .scroll {
    width: 30px;
    height: 30px;
    bottom: 30px;
    right: 20px;
  }
}

@media screen and (max-width: 480px) {
  /* Hero */
  .home-hero h1 {
    font-size: 0.9em;
    margin: 0;
  }
  .each-slide > a > span {
    font-size: 0.9em;
  }
  .home-hacemos p{
    font-size: 1em;
  }
  /* Component Slogan*/
  .home-slogan h1 {
    font-size: 0.25em;
    padding-bottom: 0;
  }
  /* Envio Component */
  .home-envio img {
    width: 18%;
    height: 18%;
    margin-right: -4%;
  }
  .envio-content h2 {
    font-size: 1.4em;
    margin-bottom: 12%;
  }
  .envio-content p {
    font-size: 0.7em;
    margin-top: -30px;
    max-width: 50vw;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-item {
    font-weight: lighter;
    display: inline-block;
    align-self: center;
    justify-self: stretch;
    font-size: 20px;
  }
  /* component infoHacemos of homepage */
  .hacemos-category-grid {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(1, auto);
    align-content: center;
    justify-content: center;
  }
  .hacemos-category-grid {
    grid-row-gap: 5px;
    grid-column-gap: 10px;
  }
  .home-hacemos-list h2 {
    font-size: 1.4em;
  }
  .hacemos-category-item {
    font-size: 0.8em;
  }
  /* Info */
  .home-info h2 {
    font-size: 1.4em;
  }
  .home-info p {
    font-size: 0.8em;
    padding: 0 10%;
  }
  .category img {
    padding-top: 0;
    width: 60%;
    height: 60%;
  }
  .category p {
    font-size: 1em;
  }
  /* Producto Category */
  .home-product-category-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-product-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #a00202;
    margin-bottom: 80px;
  }
  .home-product-category-details h2 {
    font-size: 1.4em;
    padding-top: 5%;
  }
  .home-product-category-item h3 {
    font-size: 1em;
    margin-top: 30%;
    margin-bottom: 20%;
  }
  .home-product-category-item ol li {
    font-size: 0.8em;
  }
  .btn-category .button-more-info-s {
    font-size: 0.8em;
    margin: 0;
  }
  .fix-h3 {
    font-size: 1em;
    width: 80%;
  }
  .home-product-category-details-rows h2 {
    font-size: 1.4em;
    margin-bottom: 2%;
  }
  .home-product-category-item-rows h3 {
    font-size: 1em;
    margin-top: 0;
  }
  .home-product-category-item-rows p {
    font-size: 0.8em;
  }
  /* Scroll */
  .scroll {
    width: 30px;
    height: 30px;
    bottom: 20px;
    right: 10px;
  }
  .scroll img {
    width: 15px;
    height: 15px;
  }
    /* Carrusel */
    .each-slide > a {
      text-decoration: none;
      width: 100%;
    }
    .each-slide > a > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 300px;
      background-position: center;
      margin-bottom: 20px;
      padding: -20px;
    }
  
    .each-fade {
      display: flex;
    }
  
    .each-fade .image-container {
      width: 100%;
      overflow: hidden;
    }
  
    .each-fade .image-container img {
      width: 100%;
    }
  
    .each-fade h2 {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      background: #adceed;
    }
    .react-slideshow-container{
      width: 60vw;
    }
}

@media screen and (max-width: 320px) {
  /* Hero */
  .home-hero h1 {
    text-align: justify;
  }
  /* Component Slogan*/
  .home-slogan h1 {
    font-size: 0.21em;
    padding-bottom: 10px;
  }
  .home-hacemos p{
    font-size: 0.8em;
  }
  .home-slogan .title-2 {
    margin-top: -60px;
  }
  /* Envio Component */
  .home-envio img {
    width: 20%;
    height: 20%;
    margin-right: -6%;
  }
  .envio-content h2 {
    font-size: 1em;
    margin-bottom: 18%;
  }
  .envio-content p {
    font-size: 0.6em;
    margin-top: -30px;
    max-width: 50vw;
  }
  /* component infoHacemos of homepage */
  .home-hacemos-list h2 {
    font-size: 1.2em;
  }
  .hacemos-category-item {
    font-size: 0.8em;
  }
  /* Info */
  .home-info h2 {
    font-size: 1.2em;
  }
  /*Productos categorias  */
  .home-product-category-details h2 {
    text-align: center;
  }
  .fix-h3 {
    font-size: 0.9em;
    padding-bottom: 5%;
  }
}
